(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('ContactFormModelRequest', function () {

        function ContactFormModelRequest() {
            this.adresat = 'opcja 1';
            this.addresats = [
                { name: 'pomoc@antyplagiat.pl', email: 'pomoc@antyplagiat.pl' },
                { name: 'help@strikeplagiarism.com', email: 'help@strikeplagiarism.com' }
            ];
            this.contactForm = {
                adresat: '',
                file: '',
                message: ''
            },
            this.availableExtensions = '.pdf,.png,.jpg,.jpeg,.gif,.bmp',
            this.messages = {
                success: false,
                error: false
            };
        }

        return ContactFormModelRequest;
    });
})();
