angular.module('plagiat2017App')
    .component('landingPageInvoiceModal', {
        templateUrl: 'app/landing/landing-page/landing-page-invoice.template.html',
        controller: LandingPageInvoiceController,
        controllerAs: 'vm'
    });

LandingPageInvoiceController.$inject = ['$http', '$state', '$mdDialog', '$timeout', 'items', 'Auth', 'DetectInstanceService'];

function LandingPageInvoiceController($http, $state, $mdDialog, $timeout, items, Auth, DetectInstanceService) {
    var vm = this;

    vm.closeDialog = closeDialog;
    vm.goUpload = goUpload;

    vm.invoice = {};
    vm.isLoading = false;
    vm.instanceLang = DetectInstanceService.getLandingLang();
    vm.isRomania = vm.instanceLang == 'ro';
    vm.isCompany = false;
    vm.romanianCounties = [];
    vm.romanianCities = [];

    if (vm.isRomania) {
        $http.get('/assets/ro-counties-cities.json').then(function (response) {
            vm.romanianCounties = response.data.counties;
        });
    }

    vm.onRoCountyChange = function () {
        vm.invoice.city = '';
        getCities();
    };

    function getCities() {
        if (vm.invoice.county) {
            const selectedCounty = vm.romanianCounties.find(function(c) {
                return c.name === vm.invoice.county;
            });
            vm.romanianCities = selectedCounty ? selectedCounty.cities : [];
        }
    }

    function goUpload() {
        vm.isLoading = true;
        items.onUploadWithInvoiceData(vm.invoice);
    }

    function closeDialog() {
        $mdDialog.hide();
        $timeout(function () {
            Auth.logout();
            switch (vm.instanceLang) {
                case 'ro':
                    $state.go('landing-page-ro', {key: 'error'});
                    break;
                case 'en':
                    $state.go('landing-page-int', {key: 'error'});
                    break;
                default:
                    $state.go('landing-page', {key: 'error'});
            }
        }, 1);
    }
}
