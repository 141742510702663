(function () {
    'use strict';
    angular
        .module('plagiat2017App')
        .controller('ClientAddController', ClientAddController);

    ClientAddController.$inject = [
        'ClientService', 'COUNTRYCODECONSTANT', 'CLIENTTYPECONSTANT', 'ClientEditRequestModel', '$stateParams', '$state',
        'CONTRACTTYPECONSTANT', 'ASAP_CONTRACT_INSTALLATION_CONSTANT', 'ASAP_CONTRACT_INTEGRATION_CONSTANT', 'FinderService',
        'UserService', 'ModalService', 'ContractService', 'INSTANCES', 'JhiLanguageService', 'InstanceService', 'AccountService'
    ];

    function ClientAddController(
        ClientService, COUNTRYCODECONSTANT, CLIENTTYPECONSTANT, ClientEditRequestModel, $stateParams, $state,
        CONTRACTTYPECONSTANT, ASAP_CONTRACT_INSTALLATION_CONSTANT, ASAP_CONTRACT_INTEGRATION_CONSTANT, FinderService,
        UserService, ModalService, ContractService, INSTANCES, JhiLanguageService, InstanceService, AccountService
    ) {
        var vm = this;

        this.$onInit = function () {
            onInit();
        };

        // general
        vm.client;

        vm.isReseller = AccountService.role.isReseller();
        vm.isMinistry = AccountService.role.isMinistry();

        vm.clientType = CLIENTTYPECONSTANT;
        vm.clientTypes = vm.isReseller ? CLIENTTYPECONSTANT.listForResellers : CLIENTTYPECONSTANT.listForEditClient;
        vm.clientInstances = INSTANCES.map(function (instanceConstant) {
            return instanceConstant.instance;
        });
        vm.availableLanguages = [];
        vm.clientGroups = [];
        vm.clientRegions = [];
        vm.countries = [];

        vm.basicDataStepId = 0;
        vm.stepIdCodeAccount = 1;
        vm.contractStepId = 2;
        vm.configurationStepId = 3;

        vm.saveOrUpdate = saveOrUpdate;
        vm.isEdit = isEdit;

        function onInit() {
            vm.clientId = $stateParams.id;
            JhiLanguageService.getAll().then(function (allLanguages) {
                vm.availableLanguages = allLanguages.data.availableLanguages;
            });
            if (vm.clientId) {
                //edycja klienta
                switch ($stateParams.step) {
                    case "basic-data":
                        vm.selectedStep = vm.basicDataStepId;
                        vm.basicDataStep = {completed: false, disabled: false};
                        vm.stepCodeAccount = {completed: true, disabled: true};
                        vm.contractStep = {completed: true, disabled: true};
                        vm.configurationStep = {completed: true, disabled: true};
                        break;
                    case "code-account":
                        vm.selectedStep = vm.stepIdCodeAccount;
                        vm.basicDataStep = {completed: true, disabled: true};
                        vm.stepCodeAccount = {completed: false, disabled: false};
                        vm.contractStep = {completed: true, disabled: true};
                        vm.configurationStep = {completed: true, disabled: true};
                        break;
                    case "contract":
                        vm.selectedStep = vm.contractStepId;
                        vm.basicDataStep = {completed: true, disabled: true};
                        vm.stepCodeAccount = {completed: true, disabled: true};
                        vm.contractStep = {completed: false, disabled: false};
                        vm.configurationStep = {completed: true, disabled: true};
                        break;
                    case "configuration":
                        vm.selectedStep = vm.configurationStepId;
                        vm.basicDataStep = {completed: true, disabled: true};
                        vm.stepCodeAccount = {completed: true, disabled: true};
                        vm.contractStep = {completed: true, disabled: true};
                        vm.configurationStep = {completed: false, disabled: false};
                        break;
                    default:
                        $state.go("client");
                }
                vm.isLoading = true;
                vm.loadClientGroupsAndRegions();
                ClientService.getClientForEditing(vm.clientId).then(
                    function (success) {
                        vm.client = new ClientEditRequestModel();
                        vm.client.basicData = success.data.basicData;
                        vm.client.codeAccount = success.data.codeAccount;
                        vm.client.isDatabaseExchangeProgramMember = success.data.isDatabaseExchangeProgramMember;
                        vm.client.databaseExchangeSourcesVisible = success.data.databaseExchangeSourcesVisible;
                        vm.client.clientIsHidden = success.data.clientIsHidden;
                        vm.client.contract = success.data.contract;
                        vm.client.forthcomingContract = success.data.forthcomingContract;
                        vm.client.configuration = success.data.configuration;
                        vm.client.asapContract = success.data.asapContract;
                        vm.client.findersConfiguration = success.data.findersConfiguration;
                        vm.client.parseAllDates();
                        for (var finderConfiguration in vm.client.findersConfiguration) {
                            vm.findersSelection[finderConfiguration] = true;
                        }
                        vm.isLoading = false;
                    }, function (response) {
                        ModalService.showErrorModal(response);
                        vm.isLoading = false;
                    }
                ).then(function () {
                    if (vm.selectedStep === vm.contractStepId) {
                        initializeFindersSelection();
                    }
                });
                ContractService.getHistory(vm.clientId).then(function (response) {
                    vm.contractHistory = response.data;
                    if (angular.isArray(vm.contractHistory)) {
                        vm.contractHistoryPageNumber = 1;
                        vm.contractHistoryPageSize = 5;
                        vm.contractHistoryPage = vm.contractHistory.slice(0, vm.contractHistoryPageSize);
                        vm.contractHistoryTotalNumOfRecords = vm.contractHistory.length;
                    }
                });
            } else {
                //tworzenie klienta
                vm.client = new ClientEditRequestModel();
                vm.selectedStep = vm.basicDataStepId;
                JhiLanguageService.getCurrent().then(function (language) {
                    vm.client.basicData.clieLang = language;
                });
                vm.loadClientGroupsAndRegions();

                vm.basicDataStep = {completed: false, disabled: false};
                vm.stepCodeAccount = {completed: false, disabled: true};
                vm.contractStep = {completed: false, disabled: true};
                vm.configurationStep = {completed: false, disabled: true};
            }
        }

        function saveOrUpdate() {
            vm.isSaving = true;
            if (isEdit()) {
                ClientService.update(vm.client).then(
                    function () {
                        vm.isSaving = false;
                        ModalService.showSuccessModal();
                        $state.go("client");
                    },
                    function (response) {
                        vm.isSaving = false;
                        ModalService.showErrorModal(response);
                        $mdToast.show($mdToast.simple().textContent($translate.instant("global.messages.error.label")));
                    }
                );
            } else {
                ClientService.save(vm.client).then(
                    function () {
                        vm.isSaving = false;
                        ModalService.showSuccessModal();
                        $state.go("client");
                    },
                    function (response) {
                        vm.isSaving = false;
                        ModalService.showErrorModal(response);
                    }
                );
            }
        }

        function isEdit() {
            return vm.clientId;
        }

        vm.previousStep = function () {
            /** fix jak step codeAccount jest disabled to "vm.selectedStep--;" nie przekierowuje do tylu, mimo tego "vm.selectedStep++;" przeskakuje do przodu */
            if (vm.selectedStep == vm.contractStepId 
                && vm.client.basicData.clientType.id != vm.clientType.CODE_ACCOUNT.id
                && vm.client.basicData.clientType.id != vm.clientType.COLLEGE.id) {
                vm.selectedStep = vm.basicDataStepId;
            } else {
                vm.selectedStep--;
            }
        };

        // end general

        // basic data
        vm.onStepBasicDataCompleted = function () {
            if (!isEdit()) {
                UserService.isEmailUsed(vm.client.basicData.adminEmail).then(function (response) {
                    if (response.data) {
                        ModalService.showDialogModal("client.user.email.exists");
                    } else {
                        basicDataCompleted();
                    }
                });
            } else {
                basicDataCompleted();
            }
        };

        function basicDataCompleted() {
            vm.basicDataStep.completed = true;
            if (vm.client.basicData.clientType.id === vm.clientType.CODE_ACCOUNT.id
                || vm.client.basicData.clientType.id === vm.clientType.COLLEGE.id ) {
                vm.stepCodeAccount.disabled = false;
            } else {
                vm.stepCodeAccount.disabled = true;
                vm.stepCodeAccount.completed = false;
                vm.contractStep.disabled = false;
                initializeFindersSelection();
            }
            vm.selectedStep++;

            setExchangeProgramDefaultValues();
        }

        vm.stepCodeAccountCompleted = function () {
            vm.stepCodeAccount.completed = true;
            vm.contractStep.disabled = false;
            vm.selectedStep++;
            initializeFindersSelection();
        };

        // end basic data

        // contract related functions and variables
        vm.contractType = CONTRACTTYPECONSTANT;
        vm.asapContractInstallation = ASAP_CONTRACT_INSTALLATION_CONSTANT;
        vm.asapContractIntegrationTypes = ASAP_CONTRACT_INTEGRATION_CONSTANT.arrayOfValues;

        vm.stepContractCompleted = stepContractCompleted;
        vm.findersSelection = {};
        vm.toggleFinderSelection = toggleFinderSelection;
        vm.shouldShowAsapSection = shouldShowAsapSection;
        vm.expandedContractHistoryItems = [];
        vm.chooseContractHistoryPage = chooseContractHistoryPage;
        vm.contractHistoryPageOffset = contractHistoryPageOffset;

        function chooseContractHistoryPage(pageNum) {
            vm.contractHistoryPage = vm.contractHistory.slice((pageNum - 1) * vm.contractHistoryPageSize, pageNum * vm.contractHistoryPageSize);
        }

        function contractHistoryPageOffset() {
            return (vm.contractHistoryPageNumber - 1) * vm.contractHistoryPageSize;
        }

        function toggleFinderSelection(finderId) {
            if (vm.findersSelection[finderId]) {
                if (!vm.client.findersConfiguration[finderId]) {
                    vm.client.findersConfiguration[finderId] = {id: null, queriesPerKilo: 0, visible: true};
                }
                vm.client.findersConfiguration[finderId].queriesPerKilo = vm.availableFinders.find(function (finder) {
                    return finder.id === finderId;
                }).defaultQueriesPerKilo;
                vm.client.findersConfiguration[finderId].visible = true;
                //teraz sprawdzam, czy nie trzeba wylaczyc innego findera intenetowego (0 lub 18)
                var currentFinder = vm.availableFinders.find(function (finder) {
                    return finder.id === finderId;
                });
    
                if (currentFinder && (currentFinder.finderType === 0 || currentFinder.finderType === 18)) {
                    var otherWebFinder = vm.availableFinders.find(function (finder) {
                        return finder.id !== finderId && (finder.finderType === 0 || finder.finderType === 18);
                    });
                    if (otherWebFinder) {
                        vm.findersSelection[otherWebFinder.id] = false;
                        if(!vm.client.findersConfiguration[otherWebFinder.id]) {
                            vm.client.findersConfiguration[otherWebFinder.id] = {}
                        }
                        vm.client.findersConfiguration[otherWebFinder.id].queriesPerKilo = 0;
                        vm.client.findersConfiguration[otherWebFinder.id].visible = false;
                    }
                }
            } else {
                vm.client.findersConfiguration[finderId].queriesPerKilo = 0;
                vm.client.findersConfiguration[finderId].visible = false;
            }
        }

        function shouldShowAsapSection() {
            return vm.client.basicData.clientType.id === vm.clientType.CLIE_TYPE_ASAP.id;
        }

        function stepContractCompleted() {
            vm.contractStep.completed = true;
            vm.configurationStep.disabled = false;
            vm.selectedStep++;
            setClientConfigurationDefaultValues();
        }

        function setClientConfigurationDefaultValues() {
            if (vm.client.basicData.clientType.id !== CLIENTTYPECONSTANT.CLIE_TYPE_PUBLISHING_HOUSE.id) {
                vm.client.configuration.otherSettings.permUserCheckDocumentMetricSimilarityBeforeAdd = true;
                vm.client.configuration.otherSettings.sendAlertToPromoter = true;
                vm.client.configuration.otherSettings.assignmentsEnabled = true;
                vm.client.configuration.otherSettings.assignmentsPaidCorrectionsEnabled = true;
                vm.client.configuration.otherSettings.maxAssignmentCorrections = 3;
            } else {
                vm.client.configuration.reportSettings.report2020OperatorCanEdit = true;
            }
            if (vm.client.basicData.countryId === COUNTRYCODECONSTANT.POLAND_COLUMBIA.id) {
                vm.client.configuration.userSettings.allowEditDocument = true;
                vm.client.configuration.aiDetectionSettings.forStudent = 'alwaysEnabled';
                vm.client.configuration.aiDetectionSettings.forOperator = 'defaultEnabled';
                vm.client.configuration.aiDetectionSettings.forLecturer = 'defaultEnabled';
                vm.client.configuration.aiDetectionSettings.forAdmin =    'defaultEnabled';
                vm.client.configuration.aiDetectionSettings.forApi =      'defaultEnabled';
            }
            if (vm.client.basicData.countryId === COUNTRYCODECONSTANT.POLAND_COLUMBIA.id
                && vm.client.basicData.clientType.id === CLIENTTYPECONSTANT.CLIE_TYPE_NORMAL.id) {
                vm.client.configuration.documentFieldSettings.indexNumberPresent = true;
            }
            if (vm.client.basicData.clientType.id !== CLIENTTYPECONSTANT.SCHOOL.id) {
                vm.client.configuration.otherSettings.sendAlertToPromoter = true;
            }
            if (vm.client.basicData.clientInstance === 'int') {
                vm.client.configuration.otherSettings.addPriorityFromContractEnabled = true;
                vm.client.configuration.otherSettings.dbExchangeProgramListHidden = true;
                vm.client.configuration.otherSettings.tagsEnabled = true;
                vm.client.configuration.otherSettings.peerReviewEnabled = true;
                vm.client.configuration.reportSettings.report2020PreviewSourcePlagiat = true;
            }
            if (vm.client.basicData.clientType.id === CLIENTTYPECONSTANT.COLLEGE.id) {
                vm.client.configuration.reportSettings.report2020OperatorCanEdit = true;
                // vm.client.configuration.reportSettings.warningThresholds = true;
                // vm.client.configuration.similarityReportSettings.warningThreshold1 = 30;
                // vm.client.configuration.similarityReportSettings.warningThreshold2 = 5;
                vm.client.configuration.userSettings.permUserCanChangeOrganisationalUnit = true;
                vm.client.configuration.userGroupsSettings.allowCreateStudents = true;
                vm.client.configuration.otherSettings.permUserDownloadPdfReport = true;
                vm.client.configuration.otherSettings.permUserCheckDocumentMetricSimilarityBeforeAdd = false;
                vm.client.configuration.otherSettings.maxAssignmentCorrections = 2;
            }

            if (vm.client.basicData.clientInstance === 'pl') {
                vm.client.configuration.aiDetectionSettings.aiDetectionSecondScore = true;
            }

            if (vm.client.basicData.clientInstance === 'int') {

                vm.client.configuration.reportSettings.hideDocuSimilarityFactorExt = true;
                vm.client.configuration.reportSettings.ignoringCitations = true;
                vm.client.configuration.reportSettings.excludingCitations = true;
                vm.client.configuration.reportSettings.report2020OperatorCanEdit = true;

                vm.client.configuration.addToDatabase = 'delayed';
                vm.client.configuration.addToDatabaseDays = 10;

                vm.client.configuration.userGroupsSettings.allowCreateStudents = true;

                vm.client.configuration.otherSettings.permUserCheckDocumentMetricSimilarityBeforeAdd = false;
                vm.client.configuration.otherSettings.addPriorityFromContractEnabled = false;

                if (vm.client.basicData.clieTestClient) {
                    vm.client.configuration.reportSettings.warningThresholds = true;
                    vm.client.configuration.similarityReportSettings.warningThreshold1 = 30;
                    vm.client.configuration.similarityReportSettings.warningThreshold2 = 5;

                    vm.client.configuration.otherSettings.autoTranslationsEnabled = true;
                    vm.client.configuration.grammarCheckSettings.enabled = true;
                } else {
                    vm.client.configuration.userSettings.userTeacherCanChangePromoter = true;
                    vm.client.configuration.userSettings.allowEditDocument = true;
                }

            }

            vm.client.configuration.reportSettings.report2020Disabled = false;
            vm.client.configuration.otherSettings.authorsSimplifiedFields = parseInt(vm.client.contract.contType) !== CONTRACTTYPECONSTANT.CONT_TYPE_PER_AUTHOR.id;
        }

        function setExchangeProgramDefaultValues() {
            if (!vm.client.id) {
                if (vm.client.basicData.clientType.id === CLIENTTYPECONSTANT.COLLEGE.id) {
                    vm.client.isDatabaseExchangeProgramMember = true;
                    vm.client.databaseExchangeSourcesVisible = true;
                }

                if (vm.client.basicData.clientInstance === 'int') {
                    vm.client.databaseExchangeSourcesVisible = true;

                    if (vm.client.basicData.clieTestClient) {
                        vm.client.isDatabaseExchangeProgramMember = true;
                        vm.client.clientIsHidden = true;
                    }

                }
            }
        }

        function initializeFindersSelection() {
            FinderService.getAllAvailableForClientType(vm.client.basicData.clientType.id, vm.client.basicData.countryId, vm.client.basicData.clieTestClient)
                .then(function (success) {
                    vm.availableFinders = success.data;
                    if (!isEdit()) {
                        vm.availableFinders.forEach(function (entry) {
                            if (entry.defaultQueriesPerKilo > 0) {
                                vm.findersSelection[entry.id] = true;
                                vm.toggleFinderSelection(entry.id);
                            }
                        });
                    }
                });
        }

        vm.onClientInstanceChanged = function() {
            vm.client.basicData.clieLang = null;
            vm.client.codeAccount.currencies = [];
            vm.client.codeAccount.payments = {};
            vm.instance = vm.client.basicData.clientInstance;
            vm.availableCurrencies = InstanceService.findInstanceByBrand(vm.instance).currencies.list;
            vm.availablePayments = {};
            InstanceService.findInstanceByBrand(vm.instance).currencies.settings.forEach(function (currencySetting) {
                vm.availablePayments[currencySetting.name] = currencySetting.methodsOfPayment;
            });
            initializeDefaultCodesSettings();
        };

        function initializeDefaultCodesSettings() {
            if (vm.client.id) {
                return;
            }
            if (vm.client.basicData.clientType.id === CLIENTTYPECONSTANT.COLLEGE.id) {

                if (vm.client.basicData.countryId === COUNTRYCODECONSTANT.UKRAINE.id) {
                    vm.client.codeAccount.currencies.push('UAH');
                    vm.client.codeAccount.payments.UAH = [];
                    vm.client.codeAccount.payments['UAH'].push('TYPE_EASY_PAY');

                    vm.client.codeAccount.codeTypePrices = [];
                    vm.client.codeAccount.codeTypePrices.push({name: "L",  size: 100000, currencyPrices: {UAH: 150}});
                    vm.client.codeAccount.codeTypePrices.push({name: "XL", size: 200000, currencyPrices: {UAH: 300}});
                } else if (vm.client.basicData.countryId === COUNTRYCODECONSTANT.KAZAKHSTAN_KYRGYZSTAN.id) {
                    vm.client.codeAccount.currencies.push('KZT');
                    vm.client.codeAccount.payments.KZT = [];
                    vm.client.codeAccount.payments['KZT'].push('TYPE_KASSA_24_CARD');
                    
                    vm.client.codeAccount.codeTypePrices = [];
                    vm.client.codeAccount.codeTypePrices.push({name: "L",  size: 100000, currencyPrices: {KZT: 2500}});
                    vm.client.codeAccount.codeTypePrices.push({name: "XL", size: 200000, currencyPrices: {KZT: 5000}});
                } else if (vm.client.basicData.countryId === COUNTRYCODECONSTANT.AZERBAIJAN.id) {
                    vm.client.codeAccount.currencies.push('AZN');
                    vm.client.codeAccount.payments.AZN = [];
                    vm.client.codeAccount.payments['AZN'].push('TYPE_YIGIM_AZ');

                    vm.client.codeAccount.codeTypePrices = [];
                    vm.client.codeAccount.codeTypePrices.push({name: "L",  size: 100000, currencyPrices: {AZN: 20}});
                    vm.client.codeAccount.codeTypePrices.push({name: "XL", size: 200000, currencyPrices: {AZN: 40}});
                }
            }
        }

        vm.loadClientGroupsAndRegions = function() {
            
            ClientService.getClientRegions().then(
                function (success) {
                    vm.clientRegions = success.data;
                });
            
            ClientService.getClientCountries().then(
                function (success) {
                    vm.countries = success.data;
                });
            
            ClientService.getClientGroups().then(
                function (success) {
                    vm.clientGroups = success.data;
                });

        }

    }
})();
