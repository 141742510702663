(function () {
    'use strict';

    angular.module('plagiat2017App')
        .factory('CodePaymentsRequestModel', function () {

            function PaymentInit() {
                this.productQuantity = '';

                this.price = {
                    userPaymentType: 'TYPE_TRANSFER',
                    quantity: 0,
                    currency: "EUR"
                };

                this.TYPE_TRANSFER = {
                    name: 'Przelewy24',
                    img: 'pay-przelewy24.png',
                    userPaymentType: 'TYPE_TRANSFER',
                    invoice: true,
                    backUrl: '/api/payments/code-return'
                };
                this.TYPE_PAY_PAL = {
                    name: 'Paypal',
                    img: 'pay-paypal.png',
                    userPaymentType: 'TYPE_PAY_PAL',
                    invoice: true,
                    backUrl: '/api/payments/code-return'
                };
                this.TYPE_PAY_PAL_RO = {
                    name: 'Paypal.',
                    img: 'pay-paypal.png',
                    userPaymentType: 'TYPE_PAY_PAL_RO',
                    invoice: true,
                    backUrl: '/api/payments/code-return'
                };
                this.TYPE_EASY_PAY = {
                    name: 'EasyPay',
                    img: 'pay-easypay.png',
                    userPaymentType: 'TYPE_EASY_PAY',
                    invoice: false,
                    backUrl: '/api/payments/code-return'
                };
                this.TYPE_KASSA_24 = {
                    name: 'Kassa24',
                    img: 'pay-kassa24.png',
                    userPaymentType: 'TYPE_KASSA_24',
                    invoice: true,
                    backUrl: '/api/payments/code-return'
                };
                this.TYPE_KASSA_24_CARD = {
                    name: 'Kassa24 Business',
                    img: 'pay-kassa24card.png',
                    userPaymentType: 'TYPE_KASSA_24_CARD',
                    invoice: true,
                    backUrl: '/api/payments/code-return'
                };
                this.TYPE_MOBILPAY_CARD = {
                    name: " Card",
                    img: 'pay-mobilpay-card.png',
                    userPaymentType: 'TYPE_MOBILPAY_CARD',
                    invoice: true,
                    backUrl: '/api/payments/code-return'
                };
                this.TYPE_MILLIKART_CARD = {
                    name: 'Millikart',
                    img: 'pay-millikart.png',
                    userPaymentType: 'TYPE_MILLIKART_CARD',
                    invoice: true,
                    backUrl: '/api/payments/code-return'
                };
                this.TYPE_YIGIM_AZ = {
                    name: 'Yigim.az',
                    img: 'pay-yigim.png',
                    userPaymentType: 'TYPE_YIGIM_AZ',
                    invoice: true,
                    backUrl: '/api/payments/code-return'
                };
                this.TYPE_EPAY_BG = {
                    name: 'ePay.bg',
                    img: 'pay-epay_bg.png',
                    userPaymentType: 'TYPE_EPAY_BG',
                    invoice: true,
                    backUrl: '/api/payments/code-return'
                };
                this.TYPE_BLIK = {
                    name: 'Blik',
                    img: 'pay-blik.png',
                    userPaymentType: 'TYPE_BLIK',
                    invoice: false,
                    backUrl: '/api/payments/code-return'
                };
                this.TYPE_PAYME = {
                    name: 'Payme',
                    img: 'pay-payme.png',
                    userPaymentType: 'TYPE_PAYME',
                    invoice: false,
                    backUrl: '/api/payments/code-return'
                };
                this.TYPE_FORTE_KZ = {
                    name: 'Forte.kz',
                    img: 'pay-forte-kz.png',
                    userPaymentType: 'TYPE_FORTE_KZ',
                    invoice: false,
                    backUrl: '/api/payments/code-return'
                };

            }

            return PaymentInit;
        });
})();
