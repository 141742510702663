(function () {
    'use strict';

    var restrictingDatabases = [
        {id:0, num: 64, key: 'document.metric.restricting-databases.refbooks'},
        {id:1, num: 128, key: 'document.metric.restricting-databases.local-db'},
        {id:2, num: 256, key: 'document.metric.restricting-databases.dep'},
        {id:3, num: 512, key: 'document.metric.restricting-databases.internet'},
    ];

    angular
        .module('plagiat2017App')
        .constant('RESTRICTING_DATABASES', restrictingDatabases)
})();
