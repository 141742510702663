(function () {
    'use strict';

    function CodesSearchController(
        OrganisationalUnitsService, CodeSearchModel, AccountService, CodeService, SearchService, ClientService,
        ClientQueryRequest, SearchRequest,
        USERROLES, SIMPLE_SEARCH_DELAY, PAYMENT_TYPES
    ) {
        var vm = this;

        vm.clientSearch = clientSearch;
        vm.selectedClientChange = selectedClientChange;

        vm.organisationalUnits;
        vm.userRoles;
        vm.codeTypes;
        vm.statuses;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.paymentTypes = PAYMENT_TYPES;
        vm.clientQueryRequest = new ClientQueryRequest();

        this.$onInit = function () {
            vm.isAdmin = AccountService.role.isAdmin();
            vm.isSuperAdmin = AccountService.role.isSuperAdmin();

            vm.searchRequest = vm.requestPayload.search;
            vm.simpleSearch = vm.requestPayload.simpleSearch.length === 0 ? true : false;

            findOrganisationalUnits();
            findCodeTypes();
            findUserRoles();
            findStatuses();
        };

        function selectedClientChange(item) {
            if (angular.isDefined(item)) {
                if (vm.requestPayload.search.clientId !== item.id) {
                    vm.requestPayload.search.clientId = item.id;
                    vm.search();
                }
            } else {
                vm.requestPayload.search.clientId = null;
                vm.search();
            }
        }

        function clientSearch(query) {
            vm.searchRequest = new SearchRequest();
            vm.searchRequest.pageNumber = 0;
            vm.searchRequest.pageSize = 20;
            vm.searchRequest.sortTab = SearchService.sort(vm.clientQueryRequest);
            vm.searchRequest.search = query;
            vm.searchRequest.simpleSearch = query;

            return ClientService.searchClients(vm.searchRequest).then(function(response) {
                return response.content;
            })
        }

        function findOrganisationalUnits() {
            OrganisationalUnitsService.findAllForClient().then(function(response) {
                vm.organisationalUnits = response.data;
            });
        }

        function findCodeTypes() {
            CodeService.findAllCodeTypesForCurrentClient().then(
                function (response) {
                    vm.codeTypes = response.data;
                }
            );
        }

        function findUserRoles() {
            vm.userRoles = USERROLES.getRolesForCurrentClient(AccountService);
        }

        function findStatuses() {
            vm.statuses = [
                {id: -1, translateKey: 'codes.status.unfinished'},
                {id: 0, translateKey: 'codes.status.available'},
                {id: 1, translateKey: 'codes.status.used'}
            ];
        }

        vm.toggleSearch = function () {
            vm.requestPayload.simpleSearch = '';
            vm.simpleSearch = !vm.simpleSearch;
        };

        vm.clear = function () {
            vm.requestPayload.simpleSearch = '';
            vm.requestPayload.search = new CodeSearchModel();
            vm.onSearch();
        };

        vm.search = function () {
            vm.onSearch();
        }
    }

    angular.module('plagiat2017App')
        .component('codesSearch', {
            templateUrl: 'app/codes/codes-search.template.html',
            controller: CodesSearchController,
            controllerAs: 'vm',
            bindings: {
                onSearch: '<',
                requestPayload: '<',
                pagingModel: '<',
            }
        });

})();
